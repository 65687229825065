import { Component, OnInit, ViewChild } from '@angular/core';
import { KillstatisticsService } from 'src/app/services/killstatistics.service';
import { Kill, Player, Clan, OutpostStatistic } from 'src/app/models/kill';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { PublicinterfaceService } from 'src/app/services/publicinterface.service';
import { Outpost } from 'src/app/models/outpost';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss'],
})
export class StatisticsComponent implements OnInit {
  displayedColumnsPlayer: string[] = [
    'name',
    'clan',
    'faction',
    'class',
    'kills'
  ];

  displayedColumnsClan: string[] = [
    'name',
    'members',
    'faction',
    'best',
    'kills'
  ];

  displayedColumnsOutpost: string[] = [
    'name',
    'clan',
    'faction',
    'best',
    'kills'
  ];

  displayedColumns = this.displayedColumnsPlayer;

  currentView = 'players';

  filter = {
    startDate: new Date(),
    endDate: new Date(),
  };

  kills: Kill[];
  players: Player[];
  clans: Clan[];
  outposts: OutpostStatistic[];

  outpostStates: Outpost[];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<any>;

  dataSource: MatTableDataSource<any>;

  constructor(private killService: KillstatisticsService, private outpostService: PublicinterfaceService) {
    combineLatest([this.killService.kills, this.outpostService.outposts]).subscribe(([kills, outposts]) => {
      this.kills = kills.sort((a: Kill, b: Kill) => (a.timestamp as any) - (b.timestamp as any));
      this.outpostStates = outposts;
      this.filter.startDate = new Date(this.kills[0].timestamp.setHours(0, 0 , 0 , 0));
      this.filter.endDate = new Date(this.kills[this.kills.length - 1].timestamp.setHours(24, 0, 0, 0));
      this.prepareDataSource();
    });

    this.outpostService.outposts.subscribe((outposts) => {
      this.outpostStates = outposts;
    });
  }

  prepareDataSource(target?, event?) {
    console.log(event);
    if (target && event) {
      this.filter[target] = event.value;
    }
    this.kills = this.filterWithDate();
    this.players = this.generateLeaderBoard(this.kills);
    this.outposts = this.generateOutpostBoard(this.kills);
    this.clans = this.generateClanBoard();
    this.dataSource = new MatTableDataSource(this.players);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.switchDataSource(this.currentView);
  }

  filterWithDate() {
    const kills = this.kills.filter((kill) =>
    kill.timestamp >= this.filter.startDate && kill.timestamp <= this.filter.endDate);
    console.log(kills);
    return kills;
  }

  switchDataSource(source: string) {
    switch (source) {
      case 'clans':
        this.displayedColumns = this.displayedColumnsClan;
        this.dataSource.data = this.clans;
        break;
      case 'outposts':
        this.displayedColumns = this.displayedColumnsOutpost;
        this.dataSource.data = this.outposts;
        break;
      default:
        this.displayedColumns = this.displayedColumnsPlayer;
        this.dataSource.data = this.players;
        break;
    }
    this.currentView = source;
    this.table.renderRows();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngOnInit(): void {}

  generateLeaderBoard(kills: Kill[]) {
    const sortedByKillers: Player[] = [];
    kills.forEach((kill: Kill) => {
      // if (!kill) { return; }
      const existing = sortedByKillers.find(
        (player: Player) => player.name === kill.killer.name
      );

      if (existing) {
        const existingIndex = sortedByKillers.indexOf(existing);
        sortedByKillers[existingIndex].kills += 1;
      } else {
        const player = kill.killer;
        player.kills = 1;
        sortedByKillers.push(player);
      }
    });

    return sortedByKillers;
  }

  generateClanBoard() {
    const clans: Clan[] = [];
    this.players.map((player: Player) => {
      const exists = clans.find((clan: Clan) => player.clan === clan.name);
      if (!exists) {
        clans.push({ name: player.clan, best: player, faction: player.faction, kills: player.kills || 0, members: [ player ] });
      } else {
        exists.kills += player.kills;
        exists.best = exists.best.kills > player.kills ? exists.best : player;
        exists.members.push(player);
      }
    });
    console.log(clans);
    return clans;
  }

  generateOutpostBoard(kills: Kill[]) {
    const outposts: OutpostStatistic[] = [];

    kills.map((kill: Kill) => {
      const exists = outposts.find((outpost: OutpostStatistic) => kill.outpost === outpost.name);

      if (!exists) {
        outposts.push({ name: kill.outpost, kills: kill.killer.kills || 0,
                        faction: this.outpostStates.find((outpost) => kill.outpost === outpost.title)?.faction,
                        clan: this.outpostStates.find((outpost) => kill.outpost === outpost.title)?.owner, best: kill.killer });
      } else {
        exists.kills  = kill.killer.kills ? exists.kills + kill.killer.kills : exists.kills;
        exists.best = exists.best.kills > kill.killer.kills ? exists.best : kill.killer;
      }
    });
    return outposts;
  }
}
