// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyB7-hkd41bGC-uCstwwrqc0d5PVOUSQLRI',
    authDomain: 'neocron-is.firebaseapp.com',
    databaseURL: 'https://neocron-is.firebaseio.com',
    projectId: 'neocron-is',
    storageBucket: 'neocron-is.appspot.com',
    messagingSenderId: '242624709775',
    appId: '1:242624709775:web:94188d29dee365ba5cdcfa',
    measurementId: 'G-5ZL4VJ9P8H',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
