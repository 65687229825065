import { Injectable } from '@angular/core';
import outpostBase from './data/outposts.json';
import { Outpost } from '../models/outpost';
import * as _ from 'lodash';

import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})
export class PublicinterfaceService {
  outposts: Observable<Outpost[]>;

  cities: BehaviorSubject<Outpost[]> = new BehaviorSubject(
    _.cloneDeep(outpostBase)
  );

  constructor(firestore: AngularFirestore) {
    this.cities.next(outpostBase.filter((base) => !base.sector));
    this.outposts = firestore.collection<Outpost>('outposts').valueChanges()
      .pipe(
        map((ops: any) => {
          return ops.map((entry) => {
            const lowerObj = _.transform(entry, (result, val, key) => {
              result[key.toLowerCase()] = val;
            });
            const op = outpostBase.find(
              (outpost) => outpost.sector === lowerObj.sector
            );
            lowerObj.owner = entry.Clan.Name;
            delete lowerObj.clan;
            delete lowerObj.clanid;
            delete lowerObj.id;
            delete lowerObj.reward;
            delete lowerObj.lastpaytime;
            if (!op) {
              console.log('HERE');
            }
            lowerObj.conquertime = new Date(lowerObj.conquertime);
            const newObj = _.merge(lowerObj, op);
            return newObj;
          });
        })
      );
  }

  // public getOutpostFromServer(token: string) {
  //   console.log(this.client.describe());
  //   const envelope = {
  //     token,
  //     server: 'Titan',
  //   };
  //   (this.client as any)
  //     .GetOutpostState(envelope)
  //     .subscribe((res: ISoapMethodResponse) => {
  //       console.log(res);

  //       const outpostMap = res.result.GetOutpostStateResult.Outposts.ExtendedOutpost.map(
  //         (entry) => {
  //           // service gives us uppercase stuff
  //           const lowerObj = _.transform(entry, (result, val, key) => {
  //             result[key.toLowerCase()] = val;
  //           });
  //           const op = outpostBase.find(
  //             (outpost) => outpost.sector === lowerObj.sector
  //           );
  //           lowerObj.owner = entry.Clan.Name;
  //           delete lowerObj.clan;
  //           delete lowerObj.clanid;
  //           delete lowerObj.id;
  //           delete lowerObj.reward;
  //           delete lowerObj.lastpaytime;
  //           if (!op) {
  //             console.log('HERE');
  //           }
  //           lowerObj.conquertime = new Date(lowerObj.conquertime);
  //           const newObj = _.merge(lowerObj, op);
  //           return newObj;
  //         }
  //       );
  //       this.cities.next(outpostBase.filter((base) => !base.sector));
  //       this.outposts.next(outpostMap);
  //       // this.outpostData.map((outpost) => {
  //       //   this.outposts[];
  //       // });
  //     });

  // public getOutposts(); {
  //   return this.outposts;
  // }
}
