<map name="imagemap" id="imagemap">
  <!-- Poly Cities -->
  <area
    *ngFor="let outpost of outposts"
    class="maphighlight"
    [style.left.px]="outpost.coords?.split(',')[0]"
    [style.top.px]="outpost.coords?.split(',')[1]"
    [ngClass]="outpost.faction?.replace(' ','').split('.').join('')"
    shape="{{ outpost.shape }}"
    coords="{{ outpost.coords }}"
    href="#"
    target="_self"
    appTooltip
    [outpost]="outpost"
    alt="{{ outpost.title }}"
    title="{{ outpost.title }}"
  />

  <area
    *ngFor="let city of cities"
    class="maphighlight"
    [style.left.px]="city.coords?.split(',')[0]"
    [style.top.px]="city.coords?.split(',')[1]"
    shape="{{ city.shape }}"
    coords="{{ city.coords }}"
    href="#"
    target="_self"
    alt="{{ city.title }}"
    title="{{ city.title }}"
/>
</map>
    <!-- [ngStyle]="{ 'left.px': outpost.coords.split(',')[0], 'top.px': outpost.coords.split(',')[1] }" -->
<div
  class="content_border"
  id="content_border_top"
  style="background-image: url('./assets/images/worldmap_top.jpg');"
></div>
<div
  class="content_border"W
  id="content_border_left"
  style="background-image: url('./assets/images/worldmap_left.jpg');"
></div>
<div
  class="content_border"
  id="content_border_right"
  style="background-image: url('./assets/images/worldmap_right.jpg');"
></div>

<div
  class="map"
  style="
    display: block;
    background: url('./assets/images/worldmap.jpg');
    position: relative;
    padding: 0px;
    width: 996px;
    height: 680px;
  "
>
  <canvas
    style="
      width: 996px;
      height: 680px;
      position: absolute;
      left: 0px;
      top: 0px;
      padding: 0px;
      border: 0px;
    "
    height="680"
    width="996"
  ></canvas>
  <img
    src="./assets/images/worldmap.jpg"
    alt="Neocron Worldmap"
    width="996"
    height="680"
    usemap="#imagemap"
    class="map maphilighted"
    style="
      opacity: 0;
      position: absolute;
      left: 0px;
      top: 0px;
      padding: 0px;
      border: 0px;
    "
  />
</div>
