<div id="page">
  <div id="holder">
    <div id="content">
      <router-outlet></router-outlet>
      <!-- <div id="options-menu">
        <ul>
          <li class="parent">
            <h3 id="h_factions">« Factions</h3>
            <ul>
              <li>
                <a href="#AllFactions" id="allFactions">All Factions</a>
              </li>
              <li>
                <a href="#TsunamiSyndicate" id="TsunamiSyndicate"
                  >Tsunami Syndicate (7)</a
                >
              </li>
              <li>
                <a href="#BlackDragon" id="BlackDragon"
                  >Black Dragon (12)</a
                >
              </li>
              <li>
                <a href="#CityMercs" id="CityMercs">The City Mercs (1)</a>
              </li>
              <li>
                <a href="#TwilightGuardian" id="TwilightGuardian"
                  >Twilight Guardian (16)</a
                >
              </li>
            </ul>
          </li>
        </ul>
        <ul>
          <li class="parent">
            <h3 id="h_clans">« Clans</h3>
            <ul>
              <li>
                <a
                  href="#**ExcellenceofExecution**"
                  id="clan_**excellenceofexecution**"
                  >**E x E** (3)</a
                >
              </li>
              <li>
                <a href="#thSquad" id="clan_thsquad">17th Squad (1)</a>
              </li>
              <li>
                <a href="#Infected" id="clan_infected">Infected (12)</a>
              </li>
              <li>
                <a href="#PetersPommesBude" id="clan_peterspommesbude"
                  >-=PPB=- (12)</a
                >
              </li>
              <li>
                <a href="#SystemofaDrom" id="clan_systemofadrom"
                  >SoaD (1)</a
                >
              </li>
              <li>
                <a href="#TheUsualSuspects" id="clan_theusualsuspects"
                  >[SUSPECTS] (7)</a
                >
              </li>
            </ul>
          </li>
        </ul>
        <ul>
          <li class="parent">
            <h3 id="h_clans">« FactionGRs</h3>
            <ul>
              <li>
                <a href="#GR_CityAdmin" id="genrep_cityadmin">CityAdmin</a>
              </li>
              <li>
                <a
                  href="#GR_TangentTechnologies"
                  id="genrep_tangenttechnologies"
                  >Tangent Technologies</a
                >
              </li>
              <li><a href="#GR_Biotech" id="genrep_biotech">Biotech</a></li>
              <li>
                <a href="#GR_ProtoPharm" id="genrep_protopharm"
                  >ProtoPharm</a
                >
              </li>
              <li><a href="#GR_NEXT" id="genrep_next">N.E.X.T.</a></li>
              <li>
                <a
                  href="#GR_DiamondRealEstate"
                  id="genrep_diamondrealestate"
                  >Diamond Real Estate</a
                >
              </li>
              <li>
                <a href="#GR_TsunamiSyndicate" id="genrep_tsunamisyndicate"
                  >Tsunami Syndicate</a
                >
              </li>
              <li>
                <a href="#GR_BlackDragon" id="genrep_blackdragon"
                  >Black Dragon</a
                >
              </li>
              <li>
                <a href="#GR_CrahnSect" id="genrep_crahnsect"
                  >The Crahn Sect</a
                >
              </li>
              <li>
                <a href="#GR_FallenAngels" id="genrep_fallenangels"
                  >The Fallen Angels</a
                >
              </li>
              <li>
                <a href="#GR_CityMercs" id="genrep_citymercs"
                  >The City Mercs</a
                >
              </li>
              <li>
                <a href="#GR_TwilightGuardian" id="genrep_twilightguardian"
                  >Twilight Guardian</a
                >
              </li>
            </ul>
          </li>
        </ul>
        <ul>
          <li class="parent">
            <h3 id="h_general">« General</h3>
            <ul>
              <li>
                <a href="#CitiesofNeocron" id="cities">Cities of Neocron</a>
              </li>
              <li>
                <a href="#PublicGenreps" id="poigr">Public Genreps</a>
              </li>
            </ul>
          </li>
        </ul>
      </div> -->
    </div>
    <div id="menu">
      <div id="version">Beta 0.2.0</div>
      <ul>
        <li>
          <a
            [routerLink]="['/worldmap']"
            id="menu_button_01"
            routerLinkActive="active"
            class="menu_button"
            >WORLDMAP</a
          >
        </li>
        <li>
          <a
          [routerLink]="['/statistics']"
            id="menu_button_02"
            routerLinkActive="active"
            class="menu_button"
            >LEADERBOARD</a
          >
        </li>
        <!-- <li>
          <a
            href="/web/20130719111730/http://www.neocron-is.com/history"
            id="menu_button_03"
            class="menu_button"
            >HISTORY</a
          >
        </li>
        <li>
          <a
            href="/web/20130719111730/http://www.neocron-is.com/communications"
            id="menu_button_04"
            class="menu_button"
            >COMMUNICATIONS</a
          >
        </li> -->
      </ul>
    </div>
    <div id="footer">
      <a href="#" onClick="window.open('./assets/impressum.html', '_blank')">Impressum</a>
    </div>
  </div>
</div>