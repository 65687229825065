<div class="options-bar">
  <mat-form-field class="options-bar-content" appearance="outline" color="accent" >
    <mat-label>SEARCH...</mat-label>
    <input matInput (keyup)="applyFilter($event)" />
  </mat-form-field>

  <mat-form-field appearance="outline" color="accent">
    <mat-label>Start Date</mat-label>
    <input matInput [matDatepicker]="picker" [value]="filter.startDate"  (dateChange)="prepareDataSource('startDate', $event)">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  <mat-form-field appearance="outline" color="accent">
    <mat-label>End Date</mat-label>
    <input matInput [matDatepicker]="picker2" [value]="filter.endDate"  (dateChange)="prepareDataSource('endDate', $event)">
    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
    <mat-datepicker #picker2></mat-datepicker>
  </mat-form-field>
</div>
<div id="options-menu">
    <ul>
      <li class="parent">
        <h3 id="h_factions">« View</h3>
        <ul>
          <li>
            <a (click)="switchDataSource('players')">
              > Players</a
            >
          </li>
          <li>
            <a (click)="switchDataSource('clans')">
              > Clans</a
            >
          </li>
          <li>
            <a (click)="switchDataSource('outposts')">
              > Outposts</a
            >
          </li>
        </ul>
      </li>
    </ul>
</div>
<div class="table-container">
  <div style="height: 80%;">
    <table mat-table matSort  matSortActive="kills" matSortDirection="desc" [dataSource]="dataSource">
      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <ng-container matColumnDef="clan">
        <th mat-header-cell *matHeaderCellDef>Clan</th>
        <td mat-cell *matCellDef="let element">{{ element.clan }}</td>
      </ng-container>

      <ng-container matColumnDef="best">
        <th mat-header-cell *matHeaderCellDef>Best Player</th>
        <td mat-cell *matCellDef="let element">{{ element.best?.name }}</td>
      </ng-container>
      
      <ng-container matColumnDef="faction">
          <th mat-header-cell *matHeaderCellDef>Faction</th>
          <td mat-cell *matCellDef="let element">{{ element.faction }}</td>
        </ng-container>

        <ng-container matColumnDef="members">
          <th mat-header-cell *matHeaderCellDef>Members</th>
          <td mat-cell *matCellDef="let element">{{ element.members?.length }} Member</td>
        </ng-container>
        
      <ng-container matColumnDef="class">
          <th mat-header-cell *matHeaderCellDef>Class</th>
          <td mat-cell *matCellDef="let element">{{ element.class }}</td>
        </ng-container>

      <ng-container matColumnDef="kills">
          <th mat-header-cell *matHeaderCellDef>Kills</th>
          <td mat-cell *matCellDef="let element">{{ element.kills }}</td>
      </ng-container>

      <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr> -->
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
  <mat-paginator [pageSizeOptions]="[15]"></mat-paginator>
</div>

<div
  class="content_border"
  id="content_border_top"
  [style.background-image]="
  'url(./assets/images/content_top_' + currentView + '.png)'"
></div>
<div
  class="content_border"W
  id="content_border_left"
  style="background-image: url('./assets/images/content_left.png');"
></div>
<div
  class="content_border"
  id="content_border_right"
  style="background-image: url('./assets/images/content_right.png');"
></div>