import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Kill, Player } from '../models/kill';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import outpostBase from './data/outposts.json';
import { Outpost } from '../models/outpost';

@Injectable({
  providedIn: 'root',
})
export class KillstatisticsService {
  kills: Observable<Kill[]>;

  constructor(firestore: AngularFirestore) {
    this.kills = firestore
      .collection<Kill>('killEvents')
      .valueChanges()
      .pipe(
        map((ops: any) => {
          return ops.map((entry) => {
            let isOutpost: Outpost;

            if (entry.World.toLowerCase().includes('underground')) {
              isOutpost = outpostBase.find((outpost) => {
                // find outpost with same name:
                return outpost.title.includes(entry.World.toLowerCase().replace('underground', '').trim());
              });
            } else {
              isOutpost = outpostBase.find((outpost) => {
                return entry.World === outpost.sector;
              });
            }
            if (isOutpost
              && entry.Killer.Name !== entry.Target.Name
              && entry.Killer.Clan && entry.Target.Clan
              && entry.Killer.Clan.Name !== entry.Target.Clan.Name) {
              const killer: Player = {
                clan: entry.Killer.Clan?.Name,
                class: entry.Killer.Class,
                faction: entry.Killer.Faction,
                name: entry.Killer.Name,
                profession: entry.Killer.Profession,
              };
              const target: Player = {
                clan: entry.Target.Clan?.Name,
                class: entry.Target.Class,
                faction: entry.Target.Faction,
                name: entry.Target.Name,
                profession: entry.Target.Profession,
              };
              const kill: Kill = {
                killer,
                target,
                weapon: entry.Weapon,
                timestamp: new Date(entry.TimeStamp),
                world: entry.World,
                outpost: isOutpost.title,
              };
              return kill;
            }
          }).filter(n => n);
        })
      );
  }
}
