import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WorldmapComponent } from './components/worldmap/worldmap.component';
import { StatisticsComponent } from './components/statistics/statistics.component';


const routes: Routes = [
  { path: 'worldmap', component: WorldmapComponent},
  { path: 'statistics', component: StatisticsComponent},
  { path: '**', redirectTo: 'worldmap' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
