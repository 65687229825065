import { Component, OnInit } from '@angular/core';
import { PublicinterfaceService } from 'src/app/services/publicinterface.service';
import { Outpost } from 'src/app/models/outpost';
import * as _ from 'lodash';

@Component({
  selector: 'app-worldmap',
  templateUrl: './worldmap.component.html',
  styleUrls: ['./worldmap.component.scss']
})
export class WorldmapComponent implements OnInit {

  outposts: Outpost[] = [];
  cities: Outpost[] = [];

  constructor(private publicInterface: PublicinterfaceService) {
    this.publicInterface.outposts.subscribe(ops => {
      this.outposts = ops;
    });
    this.publicInterface.cities.subscribe(cities => {
      this.cities = cities;
    });
  }

  ngOnInit(): void {

  }

}
