<div class="tooltip">
    <table class="headline" cellspacing="0" cellpadding="0">
      <tbody>
        <tr>
          <td><b>{{outpost.title}}</b><br />{{outpost.sector}}</td>
        </tr>
      </tbody>
    </table>

    <img
      [src]="'./assets/images/factions/' + outpost.faction?.split(' ').join('').split('.').join('').toLowerCase() + '.png'"
    />
    <div class="info">
      Owner:
      <font color="#006600">{{outpost.owner}}</font
      ><br />Obtained at {{outpost.conquertime | date: 'long'}}<br />from
      <font color="#FF0000">{{outpost.formerowner}}</font><br />Genrep is Attack None / GR All
    </div>
  </div>